<template>
  <div class="main">
    <div class="content" style="padding-bottom: 0">
      <div class="left flex align-center space-between">
        <div class="left flex" @click="$router.push('/school')" style="cursor: pointer">
          <el-image
            class="arrow"
            style="width: 10px; height: 18px; margin-top: 4px"
            :src="require('@/static/images/left-arrow.png')"
            lazy
          ></el-image>
          <h1 class="mg-l-20">返回</h1>
        </div>
        <div
          class="right"
          style="text-align: right; justify-content: center; cursor: pointer"
        >
          <router-link class="toShare" :to="'/school/toShare'">去分享</router-link>
        </div>
      </div>
      <div class="tab" style="margin-top: 10px">
        <div class="flex">
          <p
            v-for="(item, index) in tab"
            :key="item.value"
            @click="filterList(item.value, index)"
            :class="{ on: currentIndex == index }"
          >
            {{ item.label }}
          </p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="itembox flex flex-wrap" v-if="list.length > 0">
        <div v-for="(item, index) in list" :key="item.id">
          <div class="img" :style="{ backgroundImage: `url(${item.img})` }">
            <div class="layout"></div>
            <h1>{{ item.title }}</h1>
            <div class="bottom flex align-center space-between">
              <div class="tag">#{{ item.tag }}</div>
              <p class="toEdit" @click="toInfo(item)">详情</p>
              <!-- <router-link :to="'/school/info?id=' + item.id">详情 ></router-link> -->
            </div>
          </div>
          <div class="text">
            <h3><span>简介：</span>{{ item.remark }}</h3>
            <div class="flex bot space-between align-center">
              <p>
                <span class="logo">DAO</span>
                <i style="font-size: 16px; display: inline-block; margin-left: 5px"
                  >火鸡科学家</i
                >
              </p>
              <p class="flex align-center" @click="changeLiked(item)">
                <el-image
                  style="width: 20px"
                  v-if="!item.isLiked"
                  :src="require('../../static/images/unlike.png')"
                ></el-image>
                <el-image
                  v-else
                  style="width: 20px"
                  :src="require('../../static/images/like.png')"
                ></el-image>
                <span style="font-size: 16px; display: inline-block; margin-left: 5px">{{
                  item.likeNum
                }}</span>
              </p>
            </div>
          </div>
          <div class="flex btn space-center" style="margin-top: 20px">
            <p class="del" @click="delItem(item.id)">删除分享</p>
            <p class="pause" @click="cancelShareItem(item.id)" v-if="item.status == 3">
              暂停分享
            </p>
            <!-- <p class="pause" v-if="item.status == 2">进入草稿</p> -->
            <p
              class="toEdit"
              v-if="item.status == 0 || item.status == 2"
              @click="toEdit(item)"
            >
              编辑
            </p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="删除分享" :visible.sync="sendVisible" width="40%" center>
      <p style="font-size: 24px; text-align: center; margin-top: 0; margin-bottom: 20px">
        确定删除分享？
      </p>
      <p class="text-center">
        <span slot="footer" class="dialog-footer text-center">
          <el-button type="primary" @click="confirmDel">确 定</el-button>
          <el-button @click="sendVisible = false">取 消</el-button>
        </span>
      </p></el-dialog
    >
    <el-dialog title="暂停项目" :visible.sync="sendVisible2" width="40%" center>
      <p style="font-size: 24px; text-align: center; margin-top: 0; margin-bottom: 20px">
        确定暂停分享项目？
      </p>
      <p class="text-center">
        <span slot="footer" class="dialog-footer text-center">
          <el-button type="primary" @click="confirmCancelShare">确 定</el-button>
          <el-button @click="sendVisible2 = false">取 消</el-button>
        </span>
      </p></el-dialog
    >
  </div>
</template>
<script>
export default {
  name: "myShareList",
  data() {
    return {
      currentIndex: 0,
      status: 3,
      list: [],
      sendVisible: false,
      sendVisible2: false,
      id: "",
      tab: [
        {
          label: "分享中",
          value: 3,
        },
        {
          label: "草稿库",
          value: 0,
        },
        {
          label: "待审核",
          value: 1,
        },
        {
          label: "未通过",
          value: 2,
        },
      ],
    };
  },
  created() {
    this.getProjectList();
  },
  methods: {
    toEdit(row) {
      // row = JSON.stringify(row);
      console.log(row);
      this.$router.push({
        path: "/school/toShare",
        query: { ...row },
      });
    },
    toInfo(row) {
      // row = JSON.stringify(row);
      this.$router.push({
        path: "/school/info",
        query: { ...row },
      });
    },
    getProjectList() {
      this.$post(`/project-share/page-project-shares`, {
        size: 9,
        pageIndex: 1,
        status: this.status,
      }).then((res) => {
        res.data.data.records.map((item) => {
          item.img = item.img.replace(
            "http://www.growthx-verse.com",
            "http://47.76.52.13:9000"
          );
        });
        this.list = res.data.data.records;
      });
    },
    changeLiked(item) {
      if (item.isLiked) {
        this.$post(`/project-share/cancel-like`, {
          id: item.id,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("取消点赞成功！");
            this.getProjectList(this.status);
          }
        });
      } else {
        this.$post(`/project-share/add-like`, {
          id: item.id,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("点赞成功！");
            this.getProjectList(this.status);
          }
        });
      }
    },
    delItem(id) {
      this.sendVisible = true;
      this.id = id;
    },
    cancelShareItem(id) {
      this.sendVisible2 = true;
      this.id = id;
    },
    confirmDel() {
      this.$post(`/project-share/delete`, {
        id: this.id,
      }).then((res) => {
        if (res.data.success) {
          this.sendVisible = false;
          this.$message.success("删除成功！");
          this.getProjectList(this.status);
        }
      });
    },
    confirmCancelShare() {
      this.$post(`/project-share/stop-share`, {
        id: this.id,
      }).then((res) => {
        if (res.data.success) {
          this.sendVisible2 = false;
          this.$message.success("暂停分享成功！");
          this.getProjectList(this.status);
        }
      });
    },
    filterList(n, index) {
      this.currentIndex = index;
      this.status = n;
      this.getProjectList();
      // this.$post(`/project-share/page-project-shares`, {
      //   size: 9,
      //   pageIndex: 1,
      //   memberId: "1748712140708343809",
      // }).then((res) => {
      //   this.list = res.data.data;
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 100vh;
  padding-left: 70px;
  .itembox {
    > div {
      width: 23%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;
      margin-bottom: 20px;
      .btn {
        p {
          width: 46%;
          padding: 4px 0;
          margin: 0 4%;
        }
      }
      .text {
        padding: 20px;
        background: #fff;
        h3 {
          color: #999;
          font-weight: normal;
          height: 60px;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* 定义显示的最大行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            color: #000;
          }
          margin-bottom: 15px;
        }
        .bot {
          span.logo {
            color: #000;
            display: inline-block;
            padding: 2px 5px;
            border: 1px solid #000;
            border-radius: 4px;
          }
          i {
            font-style: normal;
          }
        }
      }
      .del {
        text-align: center;
        width: 80px;
        height: 30px;
        line-height: 30px;
        background: red;
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        z-index: 2;
      }
      .pause {
        text-align: center;
        width: 80px;
        height: 30px;
        line-height: 30px;
        background: #ffb800;
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        z-index: 2;
      }
      .toEdit {
        text-align: center;
        width: 80px;
        height: 30px;
        line-height: 30px;
        background: #41c68e;
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        z-index: 2;
      }
      .img {
        width: 100%;
        height: 25vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        padding: 30px 20px;
        box-sizing: border-box;

        .bottom {
          position: absolute;
          left: 20px;
          right: 20px;
          bottom: 20px;
          color: #fff;
          a {
            color: #fff;
            padding: 5px 10px;
            background: #41c68e;
            border-radius: 4px;
            line-height: 16px;
          }
        }
        .layout {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 0;
          left: 0;
        }
        h1 {
          color: #fff;
          position: relative;
          z-index: 2;
          font-size: 22px;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 定义显示的最大行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    > div + div {
      margin-left: 2.6%;
    }
  }
  .content {
    padding: 20px;
    h1 {
      font-size: 18px;
    }

    .right {
      width: 20%;
    }
    .toShare {
      display: inline-block;
      padding: 10px 20px;
      border-radius: 4px;
      background: #41c68e;
      color: #fff;
      line-height: 15px;
    }
    .tab p {
      padding: 10px 20px;
      color: #999;
      font-size: 18px;
      cursor: pointer;
      &.on {
        color: #000;
      }
    }
  }
}
</style>
