<template>
  <div class="main flex space-between">
    <div class="profile flex flex-column">
      <div class="top flex">
        <div class="left">
          <el-image class="avatar" :src="userInfo.avatar" lazy></el-image>
        </div>
        <div class="right mg-l-20" v-if="Object.keys(userInfo).length > 0">
          <div class="rightBox">
            <div class="flex flex-column space-arround infoBox">
              <div class="line flex space-between align-center">
                <p>
                  <span style="font-size: 18px">星民：</span
                  ><b style="font-size: 18px">{{ userInfo.name }}</b>
                </p>
                <el-button type="text" class="edit" @click="setVisible = true"
                  >编辑</el-button
                >
              </div>
              <div class="line">
                <span style="font-size: 18px">证件号：</span
                ><b style="font-size: 18px">{{ userInfo.idCardNo }}</b>
              </div>
              <div class="line flex space-between align-center">
                <!-- <p>
                  <span style="font-size: 18px">生日：</span
                  ><b>星历{{ userInfo.birthday.split(" ")[0] }}</b>
                </p> -->
                <p>
                  <span style="font-size: 18px">阶段：</span
                  ><b style="font-size: 18px">{{ userInfo.genieStageDict }}</b>
                </p>
              </div>
            </div>
            <div class="progress-container">
              <div class="progress-bar">
                <div
                  class="progress-fill"
                  :style="{
                    width:
                      (
                        userInfo.memberCapitalDO.totalGenieScore /
                        userInfo.arriveNextStageScore
                      ).toFixed(2) + '%',
                  }"
                ></div>
                <div class="fill">
                  {{ userInfo.arriveNextStageScore }} /
                  {{ userInfo.memberCapitalDO.totalGenieScore }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-button type="text" class="quit" @click="loginOut">退出登录</el-button>

      <div class="intro" style="text-align: center">成功连接无限的生长之诗—歌若思🌱</div>
      <div
        class="bottom mg-t-20"
        style="margin-top: 12px"
        v-if="Object.keys(userInfo).length > 0"
      >
        <p style="font-size: 18px">
          星灵：<b style="font-size: 18px">{{ userInfo.starGenieName }}</b>
        </p>
        <div class="img mg-t-20">
          <!-- <el-image class="star" :src="require('@/static/images/0.png')" lazy></el-image> -->
        </div>
        <!-- <div class="wallet">
          <div class="flex space-arround">
            <div class="line flex align-center">
              <el-image
                class="star"
                :src="require('@/static/images/fire.png')"
                lazy
              ></el-image>
              <p>
                <span>累计灵：</span><b>{{ userInfo.memberCapitalDO.totalGenieScore }}</b>
              </p>
            </div>
            <div class="line flex align-center">
              <el-image
                class="star"
                :src="require('@/static/images/people.png')"
                lazy
              ></el-image>
              <p>
                <span>阶段：</span><b>{{ userInfo.genieStageDict }}</b>
              </p>
            </div>
          </div>
          <div class="flex space-arround">
            <div class="line flex align-center">
              <el-image
                class="star"
                :src="require('@/static/images/card.png')"
                lazy
              ></el-image>
              <p>
                <span>信用点：</span><b>{{ userInfo.memberCapitalDO.creditScore }}</b>
              </p>
            </div>
            <div class="line flex align-center">
              <el-image
                class="star"
                :src="require('@/static/images/circle.png')"
                lazy
              ></el-image>
              <p>
                <span>火种：</span><b>{{ userInfo.memberCapitalDO.fireScore }}</b>
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="content">
      <!-- <keep-alive> -->
      <router-view></router-view>
      <!-- </keep-alive> -->
    </div>
    <el-dialog title="" :visible.sync="quitVisible" width="40%" center>
      <p style="font-size: 24px; text-align: center">确定退出该账号？</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmQuit">确 定</el-button>
        <el-button type="primary" @click="quitVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <Set
      v-if="Object.keys(userInfo).length > 0"
      :setVisible="setVisible"
      :info="userInfo"
      @updateVisible="updateVisible"
      @updateInfo="updateInfo"
      @reLogin="reLogin"
    />
  </div>
</template>
<script>
import Set from "./components/set.vue";
export default {
  name: "",
  components: {
    Set,
  },
  data() {
    return {
      quitVisible: false,
      setVisible: false,
      userInfo: {},
    };
  },
  created() {
    this.getMemberInfo();
  },
  methods: {
    updateInfo() {
      this.getMemberInfo();
      this.setVisible = false;
    },
    reLogin() {
      this.setVisible = false;
      let i = setTimeout(() => {
        localStorage.clear();
        this.$router.push("/home");
        clearTimeout(i);
      }, 1000);
    },
    getMemberInfo() {
      this.$get("/my/getMemberInfo").then((res) => {
        this.userInfo = res.data.data;
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
      });
    },
    loginOut() {
      this.quitVisible = true;
    },
    confirmQuit() {
      this.quitVisible = false;
      this.$message.success("退出成功！");
      let i = setTimeout(() => {
        localStorage.clear();
        this.$router.push("/home");
      }, 1000);
    },
    updateVisible() {
      this.setVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 100vh;
  padding-left: 70px;
  .infoBox {
    height: 120px;
    padding: 6px 0;
    padding-top: 0;
    box-sizing: border-box;
  }
  .profile {
    min-width: 420px;
    padding: 25px 20px;
    box-sizing: border-box;
    width: 32%;
    height: 100%;
    background: #fff;
    .top {
      .avatar {
        max-width: 100px;
        border-radius: 8px;
      }
      .right {
        height: 100%;
        position: relative;
        width: 80%;
        .rightBox {
          position: absolute;
          width: 100%;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        .line + .line {
          margin-top: 10px;
        }
        .line {
          span {
            display: inline-block;
            width: 80px;
          }
          .edit {
            padding: 5px 15px;
            background: #000;
            color: #fff;
            font-size: 16px;
          }
        }
      }
    }
    .quit {
      color: red;
      font-size: 16px;
      text-decoration: underline;
      padding: 5px 0;
      margin-top: 5px;
      text-align: right;
    }
    .bottom {
      flex: 1;
      .img {
        padding: 20px;
        box-sizing: border-box;
        background: #f2f2f2 url(../../static/images/0.png) no-repeat;
        background-size: 90% 90%;
        background-position: center;
        height: 90%;
        border-radius: 8px;
        .star {
          width: 82%;
        }
      }
      .wallet {
        flex-wrap: wrap;
        .line {
          min-width: 160px;
          margin-top: 20px;
          .el-image {
            width: 23px;
            height: 23px;
          }
          span {
            display: inline-block;
            margin-left: 10px;
            width: 80px;
          }
        }
      }
    }
  }
  .content {
    padding: 25px 38px;
    box-sizing: border-box;
    height: 100%;
    width: 68%;
    border-left: 1px solid #888;
    min-width: 730px;
    background: #fff;
    overflow: hidden;
  }
}
.intro {
  position: relative;
  font-size: 24px;
  font-weight: bold;
  padding: 40px 10px;
  &::before {
    content: "“";
    font-size: 50px;
    position: absolute;
    left: -30px;
    top: 0;
  }
  &::after {
    content: "”";
    font-size: 50px;
    position: absolute;
    right: 0;
    bottom: -25px;
  }
}
::v-deep .el-button--primary {
  background-color: #41c68e !important;
  border-color: #41c68e !important;
  padding: 10px 20px;
}
.progress-container {
  display: flex;
}

.progress-bar {
  width: 100%;
  // margin: 0 auto;
  height: 10px;
  background-color: #ddd;
  border-radius: 20px;
  position: relative;
  margin-top: 2px;
}

.progress-bar .current {
  position: absolute;
  top: -220%;
}
.currentName {
  position: absolute;
  bottom: -220%;
  left: 0;
  font-size: 12px;
}
.fillName {
  position: absolute;
  bottom: -220%;
  right: 0;
  font-size: 12px;
}
.progress-bar .fill {
  position: absolute;
  top: -220%;
  right: 0;
  font-size: 12px;
}

.progress-fill {
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(68deg, #fb8d4e 45.03%, #c44f02 82.13%);
  transition: width 0.5s ease;
  position: absolute;
  width: 0;
}
</style>
